@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap

$icon-chevron-down: "\EA01"
$icon-user: "\EA02"
$icon-exit: "\EA03"
$icon-chevron-down-sec: "\EA05"
$icon-chevronn-down: "\EA06"
$icon-instagram: "\EA07"
$icon-youtube: "\EA08"
$icon-close-cross: "\EA09"
$icon-password-public: "\EA10"
$icon-password-secret: "\EA11"
$icon-cross-close: "\EA12"
$icon-pencil: "\EA13"
$icon-arrow-left: "\EA14"
$icon-arrow-right: "\EA15"
$icon-search: "\EA16"
$icon-download: "\EA17"
$icon-material: "\EA18"
$icon-check: "\EA19"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-chevron-down:before
	content: $icon-chevron-down
.icon-user:before
	content: $icon-user
.icon-exit:before
	content: $icon-exit
.icon-chevron-down-sec:before
	content: $icon-chevron-down-sec
.icon-chevronn-down:before
	content: $icon-chevronn-down
.icon-instagram:before
	content: $icon-instagram
.icon-youtube:before
	content: $icon-youtube
.icon-close-cross:before
	content: $icon-close-cross
.icon-password-public:before
	content: $icon-password-public
.icon-password-secret:before
	content: $icon-password-secret
.icon-cross-close:before
	content: $icon-cross-close
.icon-pencil:before
	content: $icon-pencil
.icon-arrow-left:before
	content: $icon-arrow-left
.icon-arrow-right:before
	content: $icon-arrow-right
.icon-search:before
	content: $icon-search
.icon-download:before
	content: $icon-download
.icon-material:before
	content: $icon-material
.icon-check:before
	content: $icon-check
